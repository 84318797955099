import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
const state = {
    ordersData: [],
    openOrders: [],
    excutedOrders: [],
    orderBookTabs: [
        { name: 'Pending', id: 0, active: true, count: '0' },
        { name: 'Executed', id: 1, active: false, count: '0' },
        { name: 'Tradebook', id: 2, active: false, count: '0' },
        { name: 'Basket', id: 3, active: false, count: '0' },
        { name: 'Alerts', id: 4, active: false, count: '0' },
        { name: 'GTT', id: 5, active: false, count: '0' },
        // { name: 'SIP', id: 6, active: false, count: '0' }
    ],
    positionTabs: [
        { name: 'All Positions', id: 0, active: true, count: '0' },
        { name: 'F&O', id: 1, active: false, count: '0' },
        { name: 'MCX', id: 2, active: false, count: '0' },
        // { name: 'NSE', id: 3, active: false, count: '0' },
    ],
    infoTabs: [
        { name: 'Information', id: 0, active: true, count: '0' },
        { name: 'History', id: 1, active: false, count: '0' },
    ],
    tradeBook: [],
    objectOrdersList: '',
    orderHistory: [],
    stringOrderList : ''
}

const actions = {
    async getUserOrders({ state, commit, dispatch, rootGetters }) {
        try {
            commit('setLoader', true, { root: true })
            let response = await commonService.getOrderBook()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok" && response.data?.result) {
                commit('setOrders', response.data?.result)
            } else {
                commit('setOrders', [])
            }
        } catch (error) { 
            commit('setLoader', false, { root: true })
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    async cancelOrder({ state, commit, dispatch, rootGetters }, payload) {
        try {
            let resp = await commonService.cancelOrder(payload)
            if (resp.status == 200) {
                // await dispatch('getUserOrders')
                this.commit('setCancelOrderDialog', false, { root: true });
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async getTradeBook({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setLoader', true, { root: true })
            let json = {
                "userId": rootGetters['login/getUCC'],
                "actId": rootGetters['login/getUCC'],
            }
            let resp = await commonService.getTradeBook(json)
            if (resp.status == 200 && resp.data.status == "Ok") {
                commit('setTradeBook', resp.data?.result)
            } else {
                commit('setTradeBook', [])
            }
        } catch (error) {
            commit('setLoader', false, { root: true })
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    async setOrdersTabsCount({ state, rootGetters }, payload) {
        state.orderBookTabs.forEach(el => {
            if (el.name == 'Pending') {
                el.count = state?.openOrders.length == 0 ? '' : `(${state?.openOrders.length})`
            }
            if (el.name == 'Executed') {
                el.count = state?.excutedOrders.length == 0 ? '' : `(${state?.excutedOrders.length})`
            }
            if (el.name == 'Tradebook') {
                el.count = state.tradeBook?.length == 0 ? '' : `(${state.tradeBook?.length})`
            }
            if (el.name == 'Basket') {
                el.count = rootGetters["basket/getBasketList"]?.length == 0 ? '' : `(${rootGetters["basket/getBasketList"]?.length})`
            }
            if (el.name == 'Alerts') {
                el.count = rootGetters["alert/getAlerts"]?.length == 0 ? '' : `(${rootGetters["alert/getAlerts"]?.length})`
            }
            if (el.name == 'GTT') {
                el.count = rootGetters["gtt/getGTTList"]?.length == 0 ? '' : `(${rootGetters["gtt/getGTTList"]?.length})`
            }
            if (el.name == 'SIP') {
                el.count = rootGetters["sip/getSIPList"]?.length == 0 ? '' : `(${rootGetters["sip/getSIPList"]?.length})`
            }
        });
    },

    async getOrderHistory({ state,commit, rootGetters }, payload) {
        try {
            let json = {
                "userId": rootGetters['login/getUCC'],
                "orderNo": payload.orderNo,
            }
            let resp = await commonService.getOrderHistory(json)
            if (resp.status == 200 && resp.data.status == "Ok" && resp.data.result) {
                commit('setOrderHistory', resp.data.result)
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    checkWebsocketStatus({ state, dispatch, rootGetters }) {
        if (rootGetters['ws/getConnectionStatus'] && state.orderBookTabs[0].active) {
            var tempData = {
                data: state.orderBookTabs[0].active ? state.openOrders : [] ,
                where: 'orders'
            }
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async exitBoCoPosition({ state, commit, dispatch, rootGetters, }, payload) {
        try {
            let response = await commonService.exitBoCoPosition(payload)
            if (response.status == 200 && response.data.length && response.data[0].message != 'Unauthorized' && response.data[0].status != 'Not ok') {
                this.commit('setCancelOrderDialog', false, { root: true });
            } else if(response.data.length && response.data[0].message == 'Unauthorized')  {
                errHandle.unauthorizeHandle()
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data[0].message}`})
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
    },

    async setPositionTabsCount({ state, rootGetters }, payload) {
        state.positionTabs.forEach(el => {
            if (el.name == 'All Positions') {
                el.count = payload.all.length == 0 ? '' : `(${payload.all.length})`
            }
            if (el.name == 'F&O') {
                el.count = payload.nfo.length == 0 ? '' : `(${payload.nfo.length})`
            }
            if (el.name == 'MCX') {
                el.count = payload.mcx?.length == 0 ? '' : `(${payload.mcx?.length})`
            }
        });
    },
};

const mutations = {
    setOrders(state, payload) {
        state.ordersData = payload
        // 
        let openOrders = []
        let execOrders = []
        // var obj = {}
        let tickers = window.tickers
        let i = 0
        payload.forEach((el)=> {
            el.wsToken = `${el.exchange}|${el.token}`
            let stat = el?.orderStatus?.toLowerCase();
            if (stat == "open" || stat == 'trigger_pending') {
                openOrders.push(el)
                // obj[`${el.token}_${i}`] = el
                i += 1
            }
            if (stat == "rejected" || stat == "complete" || stat == "canceled") {
                execOrders.push(el)
            }
            if(tickers && stat == "open" ) {
                el.ltp = tickers[el.wsToken]?.lp ? tickers[el.wsToken]?.lp : el.ltp
            }
        });
        this.commit('order/setStringOrderList' , openOrders)
        this.commit('order/setOpenOrders', openOrders)
        this.commit('order/setExcutedOrders', execOrders)
        this.dispatch('order/setOrdersTabsCount', { open: state?.openOrders, exec: state?.excutedOrders, tradeBook : state?.tradeBook })
    },

    setOpenOrders(state, payload) {
        state.openOrders = payload
        this.dispatch('order/checkWebsocketStatus')
    },

    setExcutedOrders(state, payload) {
        state.excutedOrders = payload
    },

    setOrdersTabs(state, payload) {
        state.orderBookTabs = payload
    },
    setTradeBook(state, payload) {
        state.tradeBook = payload
        this.dispatch('order/setOrdersTabsCount', { open: state?.openOrders, exec: state?.excutedOrders, tradeBook : state.tradeBook })
    },
    setOrderHistory(state, payload) {
        state.orderHistory = payload
    },

    setStringOrderList(state, payload) {
        state.stringOrderList = payload
    },

    setInfoTabs(state, payload) {
        state.infoTabs = payload
    },

    setPositionTabs(state, payload) {
        state.positionTabs = payload
    },
    reset_state(state) {
        Object.assign(state,  {
            ordersData: [],
            openOrders: [],
            excutedOrders: [],
            orderBookTabs: [
                { name: 'Pending', id: 0, active: true, count: '0' },
                { name: 'Executed', id: 1, active: false, count: '0' },
                { name: 'Tradebook', id: 2, active: false, count: '0' },
                { name: 'Basket', id: 3, active: false, count: '0' },
                { name: 'Alerts', id: 4, active: false, count: '0' },
                // { name: 'GTT', id: 5, active: false, count: '0' },
                // { name: 'SIP', id: 6, active: false, count: '0' }
            ],
            positionTabs: [
                { name: 'All Positions', id: 0, active: true, count: '0' },
                { name: 'F&O', id: 1, active: false, count: '0' },
                { name: 'MCX', id: 2, active: false, count: '0' },
            ],
            infoTabs: [
                { name: 'Information', id: 0, active: true, count: '0' },
                { name: 'History', id: 1, active: false, count: '0' },
            ],
            tradeBook: [],
            objectOrdersList: '',
            orderHistory: [],
            stringOrderList : ''
        });
    },
};

const getters = {
    getOrdersData: (state) => state.ordersData,
    getOpenOrders: state => state.openOrders,
    getExcutedOrders: state => state.excutedOrders,
    getOrderBookTabs: state => state.orderBookTabs,
    getTradeBook: state => state.tradeBook,
    getObjectOrdersList: state => state.objectOrdersList,
    getOrderHistory: state => state.orderHistory,
    getInfoTabs: state => state.infoTabs,
    getPositionTabs: state => state.positionTabs
};

const orders = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default orders