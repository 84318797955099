import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'
import formula from '../../../mixins/formula';
const state = {
    etfList: [],
    etfTabs: [],
    currentEtfList: [],
    currentEtfObject: '',
    stringEtfList: '',
    ipoTabs: [
        { name: 'Ongoing', id: 0, active: true, count: '0' },
        { name: 'IPO Applied', id: 1, active: false, count: '0' },
        { name: 'Recently Listed', id: 2, active: false, count: '0' },
        { name: 'Announced', id: 3, active: false, count: '0' },
        
    ],
    dasboardtabs: [
        { name: 'Stocks', id: 0, active: true, count: '0' },
        { name: 'Mutual Funds', id: 1, active: false, count: '0' },
    ],
    activeApp: 0,
    indicestabs: [
        { name: 'Indian Indices', id: 0, active: true, count: '0' },
        { name: 'Global Indices', id: 1, active: false, count: '0' },
    ],
    indianIndices: [],
    indiceLoader: false,
    ipoActiveTab: 0,
    indiceDashBoardData: []
}
const actions = {
    async getETFDetails({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setLoader', true, { root: true })
            let json = {
                "userId": rootGetters['login/getUCC'],
            }
            let resp = await commonService.getETFDetails(json)
            if (resp.data.status == "Ok" && resp.data.message == "Success" && resp.data?.result) {
                commit('setEtfList', resp.data.result)
            } else if (resp.data.message == 'No Records Found') {
                commit('setEtfList', [])
            }
        } catch (error) {
            commit('setLoader', false, { root: true })
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    async checkWebsocketStatus({ state, commit, dispatch, rootGetters }, payload) {
        if (rootGetters['ws/getConnectionStatus'] && (payload == 'etfs' || payload == 'indices' || payload == 'indicesDash')) {
            var tempData = {
                data: payload == 'etfs' ? state.currentEtfList : payload == 'indices' ? state.indianIndices : payload == 'indicesDash' ? state.indiceDashBoardData : [],
                where: payload
            }
            await dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async getIndianIndices({ state, commit, dispatch, rootGetters }, payload) {
        commit('setIndiceLoader', true)
        commit('setIndianIndices', [])
        commit('setIndicedashboardData', [])
        await commonService.getIndianIndices().then(
                (response) => {
                    if (response.status == 200 && response.data.status == 'Ok' && response.data.message == "Success" && response.data.result && response.data.result.length > 0) {
                        for (let item of response.data.result) {
                            let tickerdata = window.tickers
                            item.wsToken = `${item.exchange}|${item.token}`
                            item.lp = item.pchg = item.chg = '0.00'
                            if (item.exchange && item.instrumentType && item.exchange && item.instrumentType.toLowerCase() == 'index') {
                                item.exchIndex = `${item.exchange.toUpperCase()}::index`
                            }
                            // if (item.exchange && item.instrumentType && item.exchange.toLowerCase() == 'bse' && item.instrumentType.toLowerCase() == 'index') {
                            //     item.exchIndex = `BSE::index`
                            // }
                            // item.tradingSymbol = item?.symbol
                            if (tickerdata && tickerdata[item.wsToken]) {
                                tickerdata[item.wsToken].lp ? item['lp'] = tickerdata[item.wsToken].lp : ''
                                item['pdc'] == 0 ? item['pdc'] = tickerdata[item.wsToken].c : ''
                                tickerdata[item.wsToken].pc ? item['pchg'] = tickerdata[item.wsToken].pc : ''
                                item['chg'] = formula.getChg(item['lp'], item['pdc'], item['exchange'])
                            }
                        }
                        if (payload == 'dashboard') {
                            commit('setIndicedashboardData', response.data?.result.slice(0, 4))
                        } else {
                            commit('setIndianIndices', response.data?.result)
                        }
                    } else {
                        notify({
                            group: "auth",
                            type: "error",
                            title: response.data.result[0]
                                ? response.data.result[0]
                                : response.data.message,
                        });
                    }
                },
                (error) => {
                    errHandle.statusCodeCheck(error)
                },
            )
            .finally(() => {
                commit('setIndiceLoader', false)
            });
    },

};

const mutations = {
    setEtfList(state, payload) {
        state.etfList = payload
    },
    setEtfTabs(state, payload) {
        let tabs = []
        payload.data.forEach((el, idx) => {
            let json = {
                name: el.etfName,
                id: idx,
                active: el.etfName == payload.sname ? true : false,
                count: el.scrips.length ? `(${el.scrips.length})` : ''
            }
            tabs.push(json)
        });
        state.etfTabs = tabs
    },
    setCurrentEtfList(state, payload) {
        state.currentEtfList = payload.data
        state.currentEtfObject = payload.object
        payload.where == 'etfs' ? this.dispatch('home/checkWebsocketStatus', payload.where) : ''
    },
    setStringEtfList(state, payload) {
        state.stringEtfList = payload
    },
    reset_state(state) {
        Object.assign(state, {
            etfList: [],
            etfTabs: [],
            currentEtfList: [],
            currentEtfObject: '',
            stringEtfList: ''
        });
    },
    setActiveTab(state, payload) {
        state.activeApp = payload
    },
    setIndianIndices(state, payload) {
        state.indianIndices = payload
        payload && payload.length > 0 ? this.dispatch('home/checkWebsocketStatus', 'indices') : ''
    },
    setIndiceLoader(state, payload) {
        state.indiceLoader = payload
    },
    setIPOTabs(state, payload) {
        state.ipoActiveTab = payload
        state.ipoTabs.forEach(el=> {
            el.active = el.id == payload
        })
    },
    setIndicesTabs(state, payload) {
        state.indicestabs.forEach(el => {
            el.active = el.name === payload
        })
    },
    setIndicedashboardData(state, payload) {
        state.indiceDashBoardData = payload
        payload && payload.length > 0 ? this.dispatch('home/checkWebsocketStatus', 'indicesDash') : ''
    }
};

const getters = {
    getEtfList: state => state.etfList,
    getEtfTabs: state => state.etfTabs,
    getCurrentEtfList: state => state.currentEtfList,

    getipoTabs: state => state.ipoTabs,
    getdasboardtabs: state => state.dasboardtabs,
    getindicestabs: state => state.indicestabs,
    getIndianIndices: state => state.indianIndices,
    getIndiceLoader: state => state.indiceLoader,
    getHoldingdashData: state => state.indiceDashBoardData,
    getStringEtfList: state => state.stringEtfList,
    getCurrentEtfObject: state => state.currentEtfObject,
    getIPOActiveTab: state => state.ipoActiveTab

};

const home = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default home