import { loginService } from '../../../_services/login.services.js';
import errHandle from '../../../handleError/errorHandling.js';
import { useNotification } from "@kyvg/vue3-notification";

import { env, service_return, endpoint } from "../../../_helpers"
const { notify } = useNotification()
import router from "../../../router/index.js"
import CryptoJS from "crypto-js";

const state = {
    errorMessage: '',
    currentMobileNo: '',
    currentEmailId: '',
    loader: false,
    stage: 'mobileNo',
    source: 'WEB',
    submitted: false,
    userRole: '',
    // 
    loginData: '',
    accessToken: '',
    currentUserId: '',
    currentPanNumber: '',
    authToken: '',
    isExistUser: false,
    scannerData: '',
    UCC: '',
    isUpdateReq: false,

    // sso
    ssoQuery: '',
    authAppcode: '',
    vendorDetails: '',
    userName: '',
    unauthorizeCount: 0,
    isValidSession: false,

    // qr
    isQrLoader:false,
    QRDetails:'',
    interval: 0,
    counter: 0,
    qrRetrySeconds: 30000, 
    sessionRetrySeconds: 2000,
    otpLoader: false,

    // device ID
    deviceId: "",
}

const actions = {
    // EKYC FLOW APIS
    async sendMobileOtp({ state, commit, dispatch, rootState }, payload) {
        // try {
            if (payload?.type != 'resend') {
                commit('setLoader', true)
            }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.ekyc,
                path: endpoint.sendMobileOtp,
                params: { "mobileNo": state.currentMobileNo },
                loaderMutation: 'login/setLoader',
                axiosType: 'EKYCAXIOS'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                commit('setStage', 'verifyNoOtp')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.reason)
            }
        // } catch (error) {

        //     commit('setLoader', false)
        //     errHandle.statusCodeCheck(error)
        // }
        // commit('setLoader', false)
    },

    async verifyMobileOtp({ state, commit, dispatch, rootState }, payload) {
        // try {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.ekyc,
                path: endpoint.verifyMobileOtp,
                params: {
                    "mobileNo": state.currentMobileNo,
                    "smsOtp": payload.otp
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'EKYCAXIOS'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                await dispatch('setAccessToken', { token: response.data.result?.authToken, type: 'init', from: 'setLoginData' })
                localStorage.setItem('applicationId', response.data.result.id)
                commit('setStage', 'email')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.reason)
            }
        // } catch (error) {
        //     commit('setLoader', false)
        //     errHandle.statusCodeCheck(error)
        // }
        // commit('setLoader',false)
    },

    async sendEmailOtp({ state, commit, dispatch, rootState }, payload) {
        // try {
            if (payload?.type != 'resend') {
                commit('setLoader', true)
            }
            // let json = {
            //     id: localStorage.getItem('applicationId'),
            //     emailId: payload.mailId,
            // };
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.ekyc,
                path: endpoint.sendEmailOtp,
                params: {
                    id: localStorage.getItem('applicationId'),
                    emailId: payload.mailId,
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'EKYCAXIOS',
                authType : 'type_2'
            }
            commit('setCurrentEmailId', payload.mailId)
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                commit('setStage', 'verifyEmailOtp')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.reason)
            }
        // } catch (error) {
        //     commit('setLoader', false)
        //     errHandle.statusCodeCheck(error)
        // }
        // commit('setLoader', false)
    },

    async verifyEmailOtp({ state, commit, dispatch, rootState }, payload) {
        // try {
            commit('setLoader', true)
            // let json = {
            //     emailId: state.currentEmailId,
            //     emailOtp: payload.otp,
            // }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.ekyc,
                path: endpoint.verifyEmailOtp,
                params: {
                    emailId: state.currentEmailId,
                    emailOtp: payload.otp,
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'EKYCAXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                commit('setStage', 'createPass')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.reason)
            }
        // } catch (error) {
        //     commit('setLoader', false)
        //     errHandle.statusCodeCheck(error)
        // }
        // commit('setLoader', false)
    },

    async createPassword({ state, commit, dispatch, rootState }, payload) {
        // try {
            commit('setLoader', true)
            // let json = {
            //     "id": localStorage.getItem('applicationId'),
            //     "password": payload.password
            // }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.ekyc,
                path: endpoint.createPassword,
                params: {
                    "id": localStorage.getItem('applicationId'),
                    "password": payload.password
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'EKYCAXIOS',
                authType : 'type_2'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                notify({ group: 'auth', type: 'success', title: `${response.data.reason}` })
                commit('setStage', 'mobileNo')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
        // } catch (error) {
        //     commit('setLoader', false)
        //     errHandle.statusCodeCheck(error)
        // }
        // commit('setLoader', false)
    },
    // EKYC FLOW APIS END //


    // WEB APP FLOW APIS //
    async verifyClient({ state, commit, dispatch, rootState }, payload) {
        

        commit('setLoader', true)
        let json = {
            method: env().HTTP_method.POST,
            module: env().module.login,
            path: endpoint.verifyClient,
            params: { "userId" : payload?.mobileNo },
            loaderMutation: 'login/setLoader',
            axiosType: 'AXIOS'
        }
        commit('setCurrentMobileNo', payload?.mobileNo)
        let response = await service_return(json)
        if (response && response.status == 200 && response.data.message == 'Success' && response.data?.result ) {
            let isExistUser = response.data?.result[0]?.isExist == 'Yes'
            let UCC = response.data?.result[0]?.ucc
            if(isExistUser) {
                commit('setIsExistUser', isExistUser)
                commit('setUCC', UCC)
                commit('setUserName', response.data?.result[0]?.name)
            }
            // commit('setCurrentUserId', UCC)
            if (!isExistUser) {
                dispatch('sendMobileOtp', { mobileNo: this.mobileNo })
            } else {
                var isUpdate = response.data?.result[0]?.updateReq
                // var isUpdate = true
                commit("setIsUpdateReq", isUpdate);
                if(isUpdate) {
                    commit("setStage", "forgotPass")
                } else {
                    commit('setStage', 'password')
                }
            }
            commit('setSubmitted', false)
        } else if(response.data && response.data.message == 'User blocked') {
            // dispatch('backToLogin', true)
            commit('setSubmitted', false)
            commit("setStage", "unblockAcc")
            // commit('setErrorMessage', response.data.message)
        }

        // disable the full page loader 
        commit('setOtpLoader' ,  false)
         
    },
    async validatePassword({ state, commit, dispatch, rootState }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.validatePassword,
                params: {
                    "userId": state.UCC || payload.mobileNo,
                    "source": state.source,
                    "password": payload.password
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS'
            }
            let response = await service_return(json)
            commit('setCurrentMobileNo', payload.mobileNo)
            if (response.status == 200 && response.data.message == 'Success' && response.data?.result) {
                let data = response.data?.result[0]
                await commit('setUserRole', data.kcRole)
                commit('setSubmitted', false)
                commit('setAuthToken', data?.token)
                if (data.kcRole == 'GUEST_USER') {
                    await dispatch('setLoginData', response.data?.result)
                    await dispatch('pref/getUserPref', { from: 'login' }, { root: true })
                } else {
                    if (data?.totpAvailable) {
                        commit('setStage', 'totp')
                    } else {
                        dispatch('loginWithOTP')
                    }
                }
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async sendWebAuthOtp({ state, commit, dispatch, rootState }, payload) {
            if (payload?.type != 'resend') {
                commit('setLoader', true)
            }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.sendWebAuthOtp,
                params: {
                    "userId": state.UCC,
                    "source": state.source
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async verifyWebAuthOtp({ state, commit, dispatch, rootState }, payload) {
            commit('setLoader', true)
            
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.verifyWebAuthOtp,
                params: {
                    "userId": state.UCC,
                    "source": state.source,
                    "otp": payload.otp,
                    "vendor": state.ssoQuery.appcode ? state.ssoQuery.appcode : undefined,
                    "deviceNumber": state.deviceId
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                await dispatch('setLoginData', response.data?.result)
                if (response.data.result[0]["authorized"] == false && state.ssoQuery != '') {
                    commit('setLoader', false)
                    await dispatch('getSessionCommon')
                    return false;
                } else if (response.data.result[0]["authorized"] == true && state.ssoQuery != '') {
                    let url = response.data.result[0].redirectUrl;
                    let where = router.currentRoute.value.path == "/" ? "_self" : "_blank";
                    await dispatch("navigateSso", { url, where })
                    where == '_self' ? commit('setOtpLoader' ,  true) : ''
                    return false
                }
                commit('setSubmitted', false)
                
                
                await dispatch('pref/getUserPref', { from: 'login' }, { root: true })
            } else {
                commit('setSubmitted', true)
                if(state.ssoQuery) {
                    notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
                } else {
                    commit('setErrorMessage', response.data.message)
                }
            }
          

    },

    async forgotPass({ state, commit, dispatch, rootState }, payload) {
            if (payload?.type != 'resend') {
                commit('setLoader', true)
            }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.forgotPass,
                params: {
                    "source": state.source,
                    "pan": payload.panNumber,
                    "userId": state.UCC
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            commit('setCurrentMobileNo', payload.mobileNo)
            commit('setCurrentPanNumber', payload.panNumber)
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
                commit('setStage', 'verifyForgotOtp')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async forgotPassVerify({ state, commit, dispatch, rootState }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.forgotPassVerify,
                params: {
                    "source": state.source,
                    "otp": payload.otp,
                    "userId": state.UCC
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success') {
                commit('setAuthToken', response.data.result[0]?.token)
                commit('setStage', 'resetPass')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async unblockAcc({ state, commit, dispatch, rootState }, payload) {
            if (payload?.type != 'resend') {
                commit('setLoader', true)
            }
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.unblockAcc,
                params: {
                    "source": state.source,
                    "pan": payload.panNumber,
                    "userId": payload.mobileNo
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            commit('setCurrentMobileNo', payload.mobileNo)
            commit('setCurrentPanNumber', payload.panNumber)
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
                commit('setStage', 'verifyUnblockOtp')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async unblockAccVerify({ state, commit, dispatch, rootState }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.unblockAccVerify,
                params: {
                    "source": state.source,
                    "otp": payload.otp,
                    "userId": payload.mobileNo
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok' && response.data.message == 'User unblocked successfully') {
                commit('setStage', 'mobileNo')
                commit('setSubmitted', false)
                notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async resetPassword({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.resetPassword,
                params: {
                    "userId": state.UCC,
                    "password": payload.password
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response?.status == 200 && response.data.status == 'Ok') {
                commit('setStage', 'mobileNo')
                commit('setSubmitted', false)
                notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async registerTotp({ state, commit, dispatch, rootGetters }) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.registerTotp,
                params: {
                    "userId": state.UCC,
                    "source": state.source
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                commit('setStage', 'verifyScannerOtp')
                commit('setSubmitted', false)
            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async getScannerData({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.getScannerData,
                params: {
                    "userId": state.UCC,
                    "source": state.source,
                    "otp": payload.otp
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                commit('setScannerData', response.data.result[0])
                commit('setStage', 'enableTotp')
                commit('setSubmitted', false)

            } else {
                commit('setSubmitted', true)
                commit('setErrorMessage', response.data.message)
            }
    },

    async enableTotp({ state, commit, dispatch, rootGetters }, payload) {
        commit('setLoader', true)
        let json = {
            method: env().HTTP_method.POST,
            module: env().module.login,
            path: endpoint.enableTotp,
            params: {
                "userId": state.UCC,
                "source": state.source,
                "totp": payload.totp
            },
            loaderMutation: 'login/setLoader',
            axiosType: 'AXIOS',
            authType : 'type_1'
        }
        let response = await service_return(json)
        if (response.status == 200 && response.data.message == 'Success') {
            commit('setStage', 'totp')
            commit('setSubmitted', false)
        } else {
            commit('setSubmitted', true)
            commit('setErrorMessage', response.data.message)
        }
    },

    async verifyTotp({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.verifyTotp,
                params: {
                    "userId": state.UCC,
                    "source": state.source,
                    "totp": payload.totp,
                    "vendor": state.ssoQuery.appcode ? state.ssoQuery.appcode : undefined,
                    "deviceNumber": state.deviceId
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if(response){
                if (response.status == 200 && response.data.status == 'Ok') {
                    await dispatch('setLoginData', response.data?.result)
                    if (response.data.result[0]["authorized"] == false && state.ssoQuery != '') {
                        await dispatch('getSessionCommon')
                        return false;
                    } else if (response.data.result[0]["authorized"] == true && state.ssoQuery != '') {
                        let url = response.data.result[0].redirectUrl;
                        let where = router.currentRoute.value.path == "/" ? "_self" : "_blank";
                        await dispatch("navigateSso", { url, where })
                        where == '_self' ? commit('setOtpLoader' ,  true) : ''                        
                        return false
                    }
                    
                    
                    await dispatch('pref/getUserPref', { from: 'login' }, { root: true })
                    await commit('setSubmitted', false)
                } else {
                    commit('setSubmitted', true)
                    if(state.ssoQuery) {
                        notify({ group: 'auth', type: 'success', title: `${response.data.message}` })
                    } else {
                        commit('setErrorMessage', response.data.message)
                    }
                }
            }
            
    },

    async loginWithOTP({ commit, dispatch }, payload) {
        commit('setStage', 'verifyWebAuthOtp')
        dispatch('sendWebAuthOtp', payload)
    },

    // SSO APIS

    async getVendorDetails({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.getVendorDetails,
                params: {
                    vendor: payload.action == 'initial' ? state.ssoQuery.appcode : payload.appcode,
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                commit('setVendorDetails', response.data.result[0])
                commit('setAppCode', json.params.vendor)
                if(state.UCC && state.accessToken){
                    payload.action == 'initial'  ? await dispatch('pref/getUserPref', { from: 'vendor' }, {root: true}) : await dispatch("checkVendorAuthorize", payload)
                }
            } else {
                notify({ group: 'auth', type: 'error', title: `${response.data?.message}` })
                commit("setSSOQuery", "");
                router.currentRoute.value.path == "/" ? router.push("/") : "";
            }
            commit('setLoader', false)
    },

    async checkVendorAuthorize({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.checkVendorAuthorize,
                params: {
                    userId: state.UCC,
                    vendor: state.authAppcode
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                if (response.data.result[0]["authorized"] == false) {
                    debugger
                    if (router.currentRoute.value.path == "/") {
                        commit('setStage', 'authorize')
                    } else { 
                        commit('setAuthDialog', true, {root:true})
                    }
                }
                let url = response.data.result[0].redirectUrl;
                let where = router.currentRoute.value.path == "/" ? "_self" : "_blank";
                if(response.data.result[0]["authorized"] == true){
                    await dispatch("navigateSso", { url, where })
                    where == '_self' ? commit('setOtpLoader' ,  true) : ''
                }
            }
            commit('setOtpLoader', false)
            commit('setLoader', false)
    },

    async vendorAuthorize({ state, commit, dispatch, rootGetters }, payload) {
            commit('setLoader', true)
            let json = {
                method: env().HTTP_method.POST,
                module: env().module.login,
                path: endpoint.vendorAuthorize,
                params: {
                    userId: state.UCC,
                    vendor: state.ssoQuery.appcode,
                },
                loaderMutation: 'login/setLoader',
                axiosType: 'AXIOS',
                authType : 'type_1'
            }
            let response = await service_return(json)
            if (response.status == 200 && response.data.status == 'Ok') {
                let url = response.data.result[0].redirectUrl;
                let where = router.currentRoute.value.path == "/" ? "_self" : "_blank";
                await dispatch("navigateSso", { url, where })
                where == '_self' ? commit('setOtpLoader' ,  true) : ''
            }
    },

    async navigateSso({ state, commit }, payload) {
        if (router.currentRoute.value.path == "/") {
            if (state.ssoQuery.rUrl) {
                payload.url = `${state.ssoQuery.rUrl}?${payload.url.split("?")[1]}`;
            }
            for (let query in state.ssoQuery) {
                query == "rUrl" ? "" : (payload.url += `&${query}=${state.ssoQuery[query]}&`);
            }
            payload.url = payload.url.substring(0, payload.url.length - 1);
        } else { }
        window.open(payload.url, payload.where);
    },

    async getSessionCommon({ dispatch }, payload) {
        await dispatch('checkVendorAuthorize')
    },

    async setLoginData({ state, rootGetters, dispatch, commit }, payload) {
        await clearInterval(state.sessionInterval)
        await clearInterval(state.interval)
        commit('setUnauthorizeCount', 0)
        state.loginData = payload[0]
        await dispatch('setAccessToken', { token: payload[0]?.accessToken, type: 'init', from: 'setLoginData' })
    },

    setAccessToken({ state, rootGetters }, payload) {
        let token = payload.token
        // let deviceId = rootGetters.getDeviceId
        // if (payload.type != 'init') {
        //     var bytes = CryptoJS.AES.decrypt(token, deviceId);
        //     var originalText = bytes.toString(CryptoJS.enc.Utf8);
        //     state.accessToken = originalText
        // }
        // if (payload.type == 'init') {
        //     state.accessToken = token
        //     var ciphertext = CryptoJS.AES.encrypt(token, deviceId).toString();
        //     localStorage.setItem('accessToken', JSON.stringify(ciphertext))
        // }
        state.accessToken = token
        localStorage.setItem('accessToken', JSON.stringify(token))
        clearInterval(state.sessionInterval)
        clearInterval(state.interval)
    },
    
    async backToLogin({ state,commit }, payload) {
        localStorage.clear()
        let tempQuery = state.ssoQuery
        commit('reset_state')
        if(tempQuery){
            commit('setSSOQuery', tempQuery)
        }
        commit('setStage', 'mobileNo');
    },

    //get QR code

    async getQrCode( { state, commit, dispatch, rootState, rootGetters }, payload ) {
        let json = {
            method: env().HTTP_method.GET,
            module: 'am/access/qr/generate',
            loaderMutation: 'login/setIsQrLoader',
            axiosType: 'QRAXIOS',
            authType : 'type_1'
        }
        let response = await service_return(json)
            if ( response.data?.status == "Ok" && response.data?.message == "Success" ) {
                commit('setQRDetails', response.data.result[0])
                clearInterval(state.interval)
                state.interval = setInterval(async () => {
                    if(state.counter <= 2 ) {
                        commit('setCounter', {count: 1, type: 'increment'})
                        await dispatch("getQrCode")
                    } else {
                        clearInterval(state.interval)
                    }
                }, state.qrRetrySeconds);
            } else {
            }
            commit('setIsQrLoader', false)
    },

    async getQrSession( { dispatch, commit }  ) {
        clearInterval(state.sessionInterval)
        let json = {
            method: env().HTTP_method.POST,
            module: env().module.login,
            axiosType: 'AXIOS',
            params:{
                qrName : state.QRDetails.qrName
            },
            path: endpoint.getQRSession,
            authType : 'type_1'
        }
            let response = await service_return(json)
            if (response.status == 200 && response.data.message == 'Success' && response.data?.result && response.data?.result.length > 0) {
                commit('setSubmitted', false)
                await clearInterval(state.sessionInterval)
                await clearInterval(state.interval)
                await dispatch('setLoginData', response.data?.result)
                commit('setUCC', response.data.result[0].clientId)
                await dispatch('pref/getUserPref', { from: 'login' }, { root: true })
            } else if((state.stage == 'mobileNo' || state.stage == 'password') && router.currentRoute.value.path == "/") {
                state.sessionInterval = setInterval(async () => {
                    if(state.counter <= 2 ) {
                        await dispatch("getQrSession")
                    } else {
                        clearInterval(state.sessionInterval)
                    }
                }, state.sessionRetrySeconds);
            }else{
                clearInterval(state.sessionInterval)
                clearInterval(state.interval)
            }
      },
}

const mutations = {
    setErrorMessage(state, payload) {
        state.errorMessage = payload
        this.commit('login/setLoader', false)
    },
    setCurrentMobileNo(state, payload) {
        state.currentMobileNo = payload
        localStorage.setItem('mobileNo', JSON.stringify(payload))
    },
    setCurrentEmailId(state, payload) {
        state.currentEmailId = payload
        localStorage.setItem('mailId', JSON.stringify(payload))
    },
    setLoader(state, payload) {
        state.loader = payload
    },
    setStage(state, payload) {
        state.stage = payload
        if(payload != 'mobileNo' && payload != 'password'){
            clearInterval(state.sessionInterval)
            clearInterval(state.interval)
        }
        localStorage.setItem('stage', JSON.stringify(payload))
    },
    setSubmitted(state, payload) {
        state.submitted = payload
    },
    setUserRole(state, payload) {
        state.userRole = payload
        localStorage.setItem('role', JSON.stringify(payload))
    },
    setCurrentUserId(state, userId) {
        state.currentUserId = userId
        localStorage.setItem('userId', JSON.stringify(userId))
    },
    setCurrentPanNumber(state, payload) {
        state.currentPanNumber = payload
        localStorage.setItem('pan', JSON.stringify(payload))
    },
    setAuthToken(state, payload) {
        state.authToken = payload
    },
    setIsExistUser(state, payload) {
        state.isExistUser = payload
        localStorage.setItem('isExistUser', JSON.stringify(payload))
    },
    setScannerData(state, payload) {
        state.scannerData = payload
    },
    setUCC(state, payload) {
        state.UCC = payload
        localStorage.setItem('UCC', JSON.stringify(payload))
    },
    setIsUpdateReq(state, payload) {
        state.isUpdateReq = payload
        localStorage.setItem("isUpdateReq", JSON.stringify(payload));
    },


    // SSO
    setSSOQuery(state, payload) {
        state.ssoQuery = payload
    },
    setVendorDetails(state, payload) {
        state.vendorDetails = payload
    },
    setUserName(state, payload){
        state.userName = payload
        localStorage.setItem('userName', JSON.stringify(payload))
    },
    setUnauthorizeCount(state, payload){
        state.unauthorizeCount = payload
    },
    reset_state(state) {
        Object.assign(state, {
            errorMessage: '',
            currentMobileNo: '',
            currentEmailId: '',
            loader: false,
            stage: 'mobileNo',
            source: 'WEB',
            submitted: false,
            userRole: '',
            loginData: '',
            accessToken: '',
            currentUserId: '',
            currentPanNumber: '',
            authToken: '',
            isExistUser: false,
            scannerData: '',
            UCC: '',
            isUpdateReq: false,
            ssoQuery: '',
            authAppcode: '',
            vendorDetails: '',
            userName: '',
            isValidSession: false
        });
    },

    setIsValidSession(state, payload) {
        state.isValidSession = payload
    },
    setAppCode(state, payload){
        state.authAppcode = payload
    },
    setIsQrLoader(state, payload) {
        state.isQrLoader = payload
    },
    setQRDetails(state, payload){
        state.QRDetails = payload
    },
    setCounter(state, payload) {
        if(payload.type == 'increment') {
            state.counter += payload.count 
        } else {
            state.counter = payload.count 
        }
    },
    setOtpLoader(state,payload) {
        state.otpLoader = payload
    },
    setDeviceId(state, payload) {
        state.deviceId = payload;
    },
};

const getters = {
    loader: state => state.loader,
    stage: state => state.stage,
    submitted: state => state.submitted,
    currentMobileNo: state => state.currentMobileNo,
    currentEmailId: state => state.currentEmailId,
    userRole: state => state.userRole,
    errorMessage: state => state.errorMessage,
    otpLoader: state => state.otpLoader,

    // 
    getLoginData: (state) => state.loginData,
    getAccessToken: (state) => state.accessToken,
    getCurrentUserId: (state) => state.currentUserId,
    currentPanNumber: (state) => state.currentPanNumber,
    getIsExistUser: (state) => state.isExistUser,
    // 
    getScannerData: (state) => state.scannerData,
    getUCC: (state) => state.UCC,
    getIsUpdateReq: (state) => state.isUpdateReq,
    getIsValidSession : (state) => state.isValidSession,
    getSsoQuery: (state) => state.ssoQuery,

    // QR
    getQrDetails: (state) => state.QRDetails,
    getInterval: (state) => state.interval,
    getCounter: (state) => state.counter,
    getIsQrLoader: (state) => state.isQrLoader,

    // get device number
    getDeviceNumber: (state) => state.deviceId
};

const login = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default login