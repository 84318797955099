import { commonService } from '../../../_services/common.service';
import errHandle from '../../../handleError/errorHandling'

const state = {
    setcorData: [],
    sectorLoader: false,
    screenerLoader: false,
    screenerData: [],
    topGainersData : [],
    topLosersData: [],
    yearHigh: [],
    yearLow:[],
    riders:[],
    draggers:[],
    volume_toppers:[],
    mean_reversion:[],
}

const mutations = {
    setSetcorData(state, payload) {
        let SectorArray = []
        if (payload.length > 0) {
            for (let item of payload) {
                let tempYear = item.oneYear.includes('%') ? item.oneYear.split('%')[0] : item.oneYear
                let formatSector = {
                    icon: 'svg',
                    sector: '',
                    key: [{ name: '1Y', class: '!text-[11px]' }, { name: '6M', class: '!text-[11px]' }, { name: '3M', class: '!text-[11px]' }],
                    value: [{ value: item.oneYear, class: Number(tempYear) > 0 ? '!text-[14px] text-[#4caf50]' : '!text-[14px] text-[#E34242]' }, { value: item.sixMonths, class: '!text-[14px] primary-color dark:text-[#94A3B8]' }, { value: item.threeMonths, class: '!text-[14px] primary-color dark:text-[#94A3B8]' }],
                    scrips: []
                }
                formatSector.sector = item.sectorName
                formatSector.scrips = item.scrips
                SectorArray.push(formatSector)
            }
        } 
        state.setcorData = SectorArray
    },

    setSectorLoader(state, payload) {
        state.sectorLoader = payload
    },

    setScreenerData(state, payload) {
        state.screenerData = []
        payload.forEach(el => {
            el.exchange = el.exch
            el.o = '0.00'
            el.h = '0.00'
            el.l = '0.00'
            el.c = '0.00'
            el.v = '0.00'
            el.wsToken = `${el.exch}|${el.token}`
        });
        state.screenerData = payload
    },

    setScreenerDataWs(state, payload) {
        state.screenerData = payload
    },

    setScreenerLoader(state, payload) {
        state.screenerLoader = payload
    },

    setTopGainersData(state, payload) {
        state.topGainersData = payload
        this.dispatch('pref/checkWebsocketStatus', 'Top Gainers', { root: true})
    },

    setTopLosersData(state, payload) {
        state.topLosersData = payload
        this.dispatch('pref/checkWebsocketStatus', 'Top Losers', { root: true})
    },
    setYearHigh(state, payload){
        state.yearHigh = payload
        this.dispatch('pref/checkWebsocketStatus', '52 High', { root: true})
    },
    setYearLow(state, payload){
        state.yearLow = payload
        this.dispatch('pref/checkWebsocketStatus', '52 Low', { root: true})
    },
    setRiders(state, payload){
        state.riders = payload
        this.dispatch('pref/checkWebsocketStatus', 'Riders', { root: true})
    },
    setDraggers(state, payload){
        state.draggers = payload
        this.dispatch('pref/checkWebsocketStatus', 'Draggers', { root: true})
    },
    setVolumeToppers(state, payload){
        state.volume_toppers = payload
        this.dispatch('pref/checkWebsocketStatus', 'Volume Toppers', { root: true})
    },
    setMeanReversion(state, payload){
        state.mean_reversion = payload
        this.dispatch('pref/checkWebsocketStatus', 'Mean Reversion', { root: true})
    },


    reset_state(state) {
        Object.assign(state, {
            setcorData: [],
            sectorLoader: false,
            screenerLoader: false,
            screenerData: [],
            topGainersData : [],
            topLosersData: [],
            yearHigh: [],
            yearLow: [],
            riders: [],
            draggers: [],
            volume_toppers: [],
            mean_reversion: []
        });
    },
}

const actions = {
    async getSectors({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setSectorLoader', true)
            let json = {
                userId: rootGetters['login/getUCC'],
            }
            let response = await commonService.getSectorDetails(json)
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setSetcorData', response.data.result)
            } else {
                commit('setSetcorData', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setSectorLoader', false)
        }
    },

    async getTopGainers({ state, commit, dispatch, rootGetters }) {
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.getTopGainers()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setTopGainersData', response.data.result)
            } else {
                commit('setScreenerData', [])
                commit('setTopGainersData', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async getTopLosers({ state, commit, dispatch, rootGetters }) {
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.getTopLosers()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setTopLosersData', response.data.result)
            } else {
                commit('setScreenerData', [])
                commit('setTopLosersData', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async yearHigh({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.yearHigh()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async yearLow({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        commit('setYearLow', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.yearLow()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setYearLow', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async riders({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        commit('setRiders', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.riders()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setRiders', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async draggers({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        commit('setDraggers', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.draggers()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setDraggers', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async topvolume({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        commit('setVolumeToppers', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.topvolume()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setVolumeToppers', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },

    async meanreversion({ state, commit, dispatch, rootGetters }) {
        commit('setScreenerData', [])
        commit('setMeanReversion', [])
        try {
            commit('setScreenerLoader', true)
            let response = await commonService.meanreversion()
            if (response.status == 200 && response.data.message == "Success" && response.data.status == "Ok") {
                commit('setScreenerData', response.data.result)
                commit('setMeanReversion', response.data.result)
            } else {
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        finally {
            commit('setScreenerLoader', false)
        }
    },
}
const getters = {
    getSectorData: (state) => state.setcorData,
    getSectorLoader: state => state.sectorLoader,
    getScreenerData: state => state.screenerData,
    getScreenerLoader: state => state.screenerLoader,
    getTopGainersData : state => state.topGainersData,
    getTopLosersData : state => state.topLosersData,
    getYearHigh: state => state.yearHigh,
    getYearLow: state => state.yearLow,
    getRiders: state => state.riders,
    getDraggers: state => state.draggers,
    getVolumeTopper: state => state.volume_toppers,
    getMeanReVersion: state => state.mean_reversion
}
const sector = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
export default sector