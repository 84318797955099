import { commonService } from '../../../_services/common.service';
import formula from '../../../mixins/formula'
import errHandle from '../../../handleError/errorHandling'

const state = {
    holdingsData: [],
    totalInvestment: 0,
    totalcurrentValue: 0,
    totalPnlChange: 0,
    daysPnlChange: 0,
    totalPnl: 0,
    daysPnl: 0,
    holdingsChartData: '',
    isAuthorization: false,
    isEdisDialog: false,
    rgbObj: {},
    isHoldServiceCalled: false,
    authHoldings: '',
    edisResp: '',
    isLoading: false,
    authBtnLoading: false,
    cdslBtnLoading: false,
    isAuthorizedAll: false,
    poaClient: '',
    authorizeList : [],
    revocationList : [],
    isEdisAuth: '',
    pludgeTransId: '',
    totalPdc: 0,
    isEdisLoader: false
}

const actions = {
    async getUserHoldings({ state, commit, dispatch, rootGetters }, payload) {
        commit('setLoader', true, { root: true })
        try {
            let response = await commonService.getUserHoldings()
            if (response.status == 200 && response.data.status == 'Ok' && response.data?.result[0]) {
                await commit('setHoldings', response.data?.result[0])
            } else {
                commit('setHoldings', [])
            }
        } catch (error) {
            errHandle.statusCodeCheck(error)
        }
        commit('setLoader', false, { root: true })
    },

    checkWebsocketStatus({ state, dispatch, rootGetters }, payload) {
        if (rootGetters['ws/getConnectionStatus']) {
            var tempData = {
                data: state.holdingsData || [],
                where: 'holdings'
            }
            dispatch("ws/websocketSubscription", tempData, { root: true })
        }
    },

    async revocationData( { state, commit, dispatch, rootGetters, rootState }, payload ) {
        try {
            commit('setAuthBtnLoading', true)
            let response = await commonService.revokeData(payload);
            if (response.status == 200 && response.data.result) {
                // commit("setPludgeDataArray", response.data.result[0]);
                commit('setEdisResp', response.data?.result[0])
            }
        } catch (error) {
            commit('setAuthBtnLoading', false)
            errHandle.methods.errorHandle(error);
        }
        commit('setAuthBtnLoading', false)
    },

    async cdslPledgeData({ state, commit, dispatch, rootGetters }, payload) {
        try {
            commit('setAuthBtnLoading', true)
            let response = await commonService.pledgeData(payload);
            if (response.status == 200 && response.data.result) {
                // commit("setPludgeDataArray", response.data.result[0]);
                commit('setEdisResp', response.data?.result[0])
            }
        } catch (error) {
            commit('setAuthBtnLoading', false)
            errHandle.methods.errorHandle(error);
        }
        commit('setAuthBtnLoading', false)
    },

    async authEdis({ state, commit, rootGetters }) {
        commit('setIsEdisLoader', true)
        await commonService.getEdis().then(
            (res) => {
                if (res.status == 200 && res.data?.result?.length) {
                    // const url = "https://skypro.skybroking.com";
                    const url = "https://edis.gopocket.in";
                    let userId = rootGetters["login/getUCC"] ? rootGetters["login/getUCC"] : "";
                    let hstoken = res.data.result[0]?.hstk;
                    let brokerId = res.data.result[0]?.brkname;
                    let edisString = `sLoginId=${userId}&sAccountId=${userId}&prd=C&token=${hstoken}&sBrokerId=${brokerId}`;
                    let encodedString = window.btoa(edisString);
                    let endpoint = `/NorenEdis/NonPoaHoldings?`
                    let redirectUrl = `${url}${endpoint}${encodedString}`;
                    if(redirectUrl) window.open ? window.open(redirectUrl, '_self','noopener') : window.location.href = redirectUrl;
                }
            },
            (error) => {
                commit('setIsEdisLoader', false)
                errHandle.methods.errorHandle(error);
            }
        ).finally(() => {
            commit('setIsEdisLoader', false)
        });
    },
};

const mutations = {
    setIsHoldServiceCalled(state, payload) {
        state.isHoldServiceCalled = payload
    },
    setHoldings(state, payload) {
        state.authorizeList = []
        state.revocationList = []
        state.poaClient = payload['poa']
        // 
        let tickers = window.tickers
        let obj = {}
        let isAuthorizedAll = false
        if (payload?.holdings) {
            payload.holdings.forEach(el => {
                let nseExch = el.symbol.filter(el =>{
                    return el.exchange == "NSE"
                })
                let bseExch = el.symbol.filter(el =>{
                    return el.exchange == "BSE"
                })
                if(nseExch && nseExch.length > 0){
                    el.ltp = nseExch[0].ltp ? nseExch[0].ltp : '0.00'
                    el.pdc = nseExch[0].pdc ? nseExch[0].pdc : '0.00'
                    el.exchange = nseExch[0].exchange
                    el.token =  nseExch[0].token
                    el.tradingSymbol = nseExch[0].tradingSymbol
                }

                if(nseExch && nseExch.length == 0 && bseExch && bseExch.length > 0){
                    el.ltp = bseExch[0].ltp ? bseExch[0].ltp : '0.00'
                    el.pdc = bseExch[0].pdc ? bseExch[0].pdc : '0.00'
                    el.exchange = bseExch[0].exchange
                    el.token =  bseExch[0].token
                    el.tradingSymbol = bseExch[0].tradingSymbol
                }
                el.lp = 0
                el.wsToken = `${el.exchange}|${el.token}`
                
                el.buyPrice = el?.buyPrice ? parseFloat(el?.buyPrice).toFixed(2) : el?.buyPrice
                if (!state.rgbObj[el.token]) {
                    el.rgb = state.rgbObj[el.token]
                }
                if (tickers) {
                    el.ltp = tickers[el.wsToken]?.lp ? tickers[el.wsToken]?.lp : el.ltp
                    el.pdc = tickers[el.wsToken]?.c ? tickers[el.wsToken]?.c : el.pdc
                }
                if (el.netQty == el.sellableQty) {
                    isAuthorizedAll = true
                }
                if (el.netQty != el.sellableQty) {
                    isAuthorizedAll = false
                }

                el.qtyvmodel = el.netQty
                el.haveError = false
                el.isChecked = false
                if (payload['poa'] == false) {
                    if (!el.authFlag) {
                        state.authorizeList.push(el);
                    }
                    if (el.authFlag) {
                        state.revocationList.push(el);
                    }
                }
                obj[el.wsToken] = el
            });
        }
        this.commit('holdings/setIsAuthorizedAll', isAuthorizedAll)
        this.commit('holdings/setHoldingsData', payload.holdings ? payload.holdings : [])
        this.commit('holdings/setIsAuthorization', payload.poa ? payload.poa : false)
        this.commit('holdings/setObjectHoldingList', obj)
        state.totalcurrentValue = parseFloat(state.totalcurrentValue).toFixed(2)
        state.totalPnl = parseFloat(state.totalPnl).toFixed(2)
        state.daysPnl = parseFloat(state.daysPnl).toFixed(2)
        this.dispatch('holdings/checkWebsocketStatus')
        this.commit('holdings/setHoldingsChartData', '')
        formula.holdingsProfitLoss('Intial')
        this.commit('holdings/setRgbObj', state.rgbObj)
    },
    setHoldingsData(state, payload) {
        state.holdingsData = payload
        if (state?.holdingsData?.length != 0) {
            sessionStorage.setItem('holdingsData', JSON.stringify(state.holdingsData))
        }
    },
    setObjectHoldingList(state, payload) {
        state.objectHoldingList = payload
    },
    setHoldingsChartData(state, payload) {
        state.holdingsChartData = payload
    },
    setIsAuthorization(state, payload) {
        state.isAuthorization = payload
    },
    setIsEdisDialog(state, payload) {
        state.isEdisDialog = payload
    },
    setRgbObj(state, payload) {
        state.rgbObj = payload
        localStorage.setItem('rgbObj', JSON.stringify(state.rgbObj))
    },
    setAuthHoldings(state, payload) {
        payload.forEach(el => {
            el.qtyvmodel = el.netQty
            el.haveError = false
            el.isChecked = false
        })
        state.authHoldings = payload
    },
    setEdisResp(state, payload) {
        if (state.isEdisAuth == 'Revoke') {
            state.pludgeTransId = payload.revokDtls;
        } else {
            state.pludgeTransId = payload.transDtls;
        }
        state.edisResp = payload
    },
    setIsLoading(state, payload) {
        state.isLoading = payload
    },
    setAuthBtnLoading(state, payload) {
        state.authBtnLoading = payload
    },
    setCdslBtnLoading(state, payload) {
        state.cdslBtnLoading = payload
    },
    setIsAuthorizedAll(state, payload) {
        state.isAuthorizedAll = payload
    },
    setAuthFlag(state, payload) {
        state.authFlag = payload
    }, 
    setIsEdisAuth(state, payload) {
        state.isEdisAuth = payload
    }, 
    setIsEdisLoader(state, payload) {
        state.isEdisLoader = payload
    },
    reset_state(state) {
        Object.assign(state, {
            holdingsData: [],
            totalInvestment: 0,
            totalcurrentValue: 0,
            totalPnlChange: 0,
            daysPnlChange: 0,
            totalPnl: 0,
            daysPnl: 0,
            holdingsChartData: '',
            isAuthorization: false,
            isEdisDialog: false,
            rgbObj: {},
            isHoldServiceCalled: false,
            authHoldings: '',
            edisResp: '',
            isLoading: false,
            authBtnLoading: false,
            cdslBtnLoading: false,
            isAuthorizedAll: false,
            poaClient: '',
            authorizeList : [],
            revocationList : [],
            isEdisAuth: '',
            pludgeTransId: '',
            totalPdc: 0,
            isEdisLoader: false
        });
    },
};

const getters = {
    getHoldingsData: (state) => state.holdingsData,
    //
    getTotalInvestment: (state) => state.totalInvestment,
    getTotalcurrentValue: (state) => state.totalcurrentValue,
    getTotalPnlChange: (state) => state.totalPnlChange,
    getDaysPnlChange: (state) => state.daysPnlChange,
    getDaysPnl: (state) => state.daysPnl,
    getTotalPnl: (state) => state.totalPnl,
    getHoldingsObject: (state) => state.objectHoldingList,
    getIsAuthorization: (state) => state.isAuthorization,
    getIsEdisDialog: (state) => state.isEdisDialog,
    getAuthHoldings: (state) => state.authHoldings,
    getEdisResp: (state) => state.edisResp,
    getIsLoading: (state) => state.isLoading,
    getAuthBtnLoading: (state) => state.authBtnLoading,
    getIsAuthorizedAll: (state) => state.isAuthorizedAll,
    getAuthFlag: (state) => state.authFlag,
    getAuthorizeList: (state) => state.authorizeList,
    getRevocationList: (state) => state.revocationList,
    getIsEdisAuth: (state) => state.isEdisAuth,
    getPludgeTransId: (state) => state.pludgeTransId,
    getPoaClient: (state) => state.poaClient,
    getIsEdisLoader: (state) => state.isEdisLoader
};

const holdings = {
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}

export default holdings